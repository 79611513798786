<script setup lang="ts">
import { computed } from '#imports'

const props = defineProps<{
  modelValue?: string[]
  label: string
  required?: boolean
}>()

defineEmits(['click'])

const isActive = computed(() => Boolean(props.modelValue?.length))
</script>

<template>
  <button
    class="inline-flex h-full max-h-10 items-center justify-center space-x-2 rounded-full border border-neutral-200 px-3 py-2"
    :class="{
      'bg-secondary-50 text-secondary-800 border-transparent': isActive,
      'text-neutral-800': !isActive,
    }"
    type="button"
    @click="$emit('click')"
  >
    <span class="subtitle-2 flex">
      <slot name="icon" />
      <span class="max-w-xs truncate whitespace-nowrap">
        {{
          modelValue?.length ? `${label}: ${modelValue[0]}` : label
        }}
      </span>
      <span v-if="modelValue?.length && modelValue.length > 1">, +{{ modelValue?.length - 1 }}</span>
      <span class="i-ph-caret-down-bold ml-2 h-4 w-4 rounded-full" />
    </span>
  </button>
</template>
