<script setup lang="ts">
import type { Api } from '@rialtic/api'
import { useApi } from '~/stores/api'

const api = useApi()

const root = ref(null)
const chip = ref(null)

const activeFilters = ref({} as Record<string, string>)
const open = ref(false)

const activeFilterLabels = computed(() => Object.values(activeFilters.value))

const clearFilters = () => {
  activeFilters.value = {}
  open.value = false
}

const removeFilter = (itemKey: string) => {
  const filters = { ...activeFilters.value }
  delete filters[itemKey]
  activeFilters.value = filters
}

const updateFilters = (item: Api.ReviewStatus) => {
  if (!item) return

  if (activeFilters.value[item.id]) removeFilter(item.id)
  else activeFilters.value = { ...activeFilters.value, [item.id]: item.status }
}

const emit = defineEmits(['change'])
watch(activeFilters, (val) => {
  emit('change', val)
})

const route = useRoute()
onMounted(() => {
  api.reviewStatus.getMany()
  const { query } = route

  if (!query.topic || typeof query.topic !== 'string') return

  const value = decodeURIComponent(query.topic)
  const active = api.reviewStatusState.data?.find(
    (item) => item.status === value,
  )

  if (active) updateFilters(active)
})
</script>

<template>
  <div ref="root" class="relative inline-block">
    <div ref="chip" class="h-full">
      <UiFilterChip
        v-model="activeFilterLabels"
        class="h-full"
        label="Review Status"
        @clear="clearFilters()"
        @click="open = true"
      />
    </div>
    <UiFloatingUi
      v-if="open"
      class="bg-white shadow-lg"
      :anchor="chip"
      placement="bottom-start"
      @click-outside="open = false"
    >
      <UiFilterMenu
        title="Review Status"
        @clear="clearFilters()"
        @close="open = false"
      >
        <ul class="p-1">
          <li v-for="item in api.reviewStatusState.data" :key="item.id">
            <UiListItemCheckbox
              :checked="!!activeFilters[item.id]"
              @click="updateFilters(item)"
            >
              {{ item.status }}
            </UiListItemCheckbox>
          </li>
        </ul>
      </UiFilterMenu>
    </UiFloatingUi>
  </div>
</template>
